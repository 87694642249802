<template>
  <div class="topCenter">
    <div v-for="(item,index) in dataSource" :key="index" class="item">
      <div>
        <p>{{ item.title }}</p>
        <p>{{ item.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topCenter",
  data() {
    return {
      description: ""
    }
  },
  computed: {
    dataSource() {
      return [{
        title: "粪污总量(吨)",
        value: "3,250,000"
      }, {
        title: "秸秆总量(吨)",
        value: "3,250,000"
      }, {
        title: "加工产值(万元)",
        value: "2,309.860"
      }]
    }
  }
}
</script>

<style lang="less" scoped>
.topCenter {
  display: grid;
  grid-template-columns: 1.9058rem 1.9058rem 1.9058rem;
  grid-template-rows: 7.657vh;
  grid-column-gap: 0.242rem;

  .item {
    background: url("~@/assets/imgs/itemBoxBJ-ct.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 0.193rem;
    display: flex;
    align-items: center;

    > div {
      p:first-child {
        color: #01FFFF;
        font-size: 0.16rem;
      }

      p:last-child {
        color: #00FFF4;
        font-size: 0.2rem;
        font-weight: 700;
      }
    }
  }
}
</style>
