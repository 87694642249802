<template>
  <div class="monitor">
    <div class="sel-box">
      <Select v-model="siteGuid" style="width: 200px">
        <Option
          v-for="item in getSites"
          :value="item.sensorGuids"
          :key="item.guid"
          >{{ item.name }}</Option
        >
      </Select>
      <span class="close" @click="close">×</span>
    </div>
    <div class="monitor-content">
      <div class="cont-box" v-for="(item, index) in monitorData" :key="index">
        <div class="img-box">
          <img :src="getImgUrl(item.servicePath, item.imgUrl)" alt="" />
        </div>
        <div class="cont-item">
          <p class="item-num">{{ item.measure + item.sensorCategoryUnit }}</p>
          <p class="item-name">{{ item.sensorCategoryName }}</p>
          <p class="item-time">{{ item.updateTime }}</p>
        </div>
        <a
          class="chart-icon"
          title="查看近期数据曲线"
          @click="() => showChart(item)"
        >
          <img src="imgs/line.png" alt="" />
        </a>
      </div>
    </div>
    <div class="left-top-corner"></div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  name: "",
  components: {
    surroundBox,
  },
  props: {
    site: Object,
  },
  data() {
    return {
      siteGuid: "",
      monitorData: [],
    };
  },
  computed: {
    ...mapState({
      sites: (state) => state.map.sites,
    }),
    getSites() {
      return this.sites.filter(
        (item) => item.siteType == 1 || item.siteType == 6
      );
    },
  },
  watch: {
    siteGuid(n) {
      this.getMonitorValue(n);
    },
    site(n) {
      if (!n) return;
      this.siteGuid = n.sensorGuids;
    },
  },
  methods: {
    close() {
      this.$emit("monitor-close");
    },
    getMonitorValue(sensorGuids) {
      this.$post(this.$api.BUSSDEVICESENSOR.LIST, {
        guidArr: sensorGuids,
      }).then((res) => {
        this.monitorData = res.list;
      });
    },
    showChart(data) {
      this.$emit("show-chart", {
        ...data,
        siteInfo: { ...this.site },
        sensorList: this.monitorData,
      });
    },
  },
  mounted() {
    if (this.site) {
      this.siteGuid = this.site.sensorGuids;
    }
  },
};
</script>

<style lang="less" scoped>
.monitor {
  width: 100%;
  height: 100%;
  padding: 16px;
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    270deg,
    rgba(14, 57, 51, 0.2) 0%,
    rgba(14, 57, 51, 0.478) 12%,
    rgba(14, 57, 51, 0.6) 90%,
    rgba(14, 57, 51, 0.8) 100%
  );

  .sel-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      cursor: pointer;
      color: #e0e0e0;
      font-size: 20px;
      &:hover {
        color: #fff;
      }
    }
    /deep/ .ivu-select-selection {
      // background-color: rgba(23, 34, 60, 0.6);
      background: linear-gradient(
        180deg,
        rgba(14, 56, 50, 0.28) 0%,
        rgba(3, 42, 34, 0.81) 100%
      );
      // border: none;
      border: 1px solid #00fff7;
      box-shadow: none;
    }

    /deep/ .ivu-select-dropdown {
      // background-color: rgba(23, 34, 60, 1);
      background: linear-gradient(
        180deg,
        rgba(14, 56, 50, 0.28) 0%,
        rgba(3, 42, 34, 0.81) 100%
      );
    }

    /deep/ .ivu-select-selected-value {
      color: #fff;
    }

    /deep/ .ivu-select-placeholder {
      color: #fff;
    }

    /deep/ .ivu-select-arrow {
      color: #fff;
    }

    /deep/ .ivu-select-item {
      color: #fff;
    }

    /deep/ .ivu-select-item:hover {
      // background: rgba(61, 90, 94, 0.78);
      background: linear-gradient(
        180deg,
        rgba(14, 56, 50, 0.28) 0%,
        rgba(3, 42, 34, 0.81) 100%
      );
    }

    /deep/ .ivu-select-item-focus {
      // background: rgba(61, 90, 94, 0.78);
      background: linear-gradient(
        180deg,
        rgba(14, 56, 50, 0.28) 0%,
        rgba(3, 42, 34, 0.81) 100%
      );
    }
  }
  .monitor-content {
    height: calc(100% - 32px);
    display: flex;
    justify-content: space-between;
    // align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .cont-box {
      width: calc((100% - 16px) / 2);
      height: 80px;
      border: 1px solid #fff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-top: 10px;
      .img-box {
        width: 60px;
        height: 60px;
        border: 1px dashed #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .cont-item {
        color: #fff;
        padding-left: 10px;
        position: relative;
        .item-num {
          color: #06baa1;
          font-size: 22px;
        }
        .item-time {
          font-size: 12px;
        }
      }
      .chart-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .left-top-corner {
    width: 16px;
    height: 16px;
    border-top: 2px solid #00fff7;
    border-left: 2px solid #00fff7;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .right-top-corner {
    width: 16px;
    height: 16px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .right-bottom-corner {
    width: 16px;
    height: 16px;
    border-bottom: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .left-bottom-corner {
    width: 16px;
    height: 16px;
    border-bottom: 2px solid #00fff7;
    border-left: 2px solid #00fff7;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
}
</style>