<template>
  <surroundBox :name="description" desc="废弃物加工得到的产品产量">
    <template slot="content">
      <highcharts :callback="loadData" :options="chartOptions" style="width: 100%;height: 100%"></highcharts>
    </template>
  </surroundBox>

</template>

<script>
import {Chart} from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import highcharts3d from 'highcharts/highcharts-3d'
import surroundBox from "../surroundBox/surroundBox";

exportingInit(Highcharts)
highcharts3d(Highcharts)
export default {
  name: "recyclingProductForm",
  components: {
    highcharts: Chart,
    surroundBox,
  },
  data() {
    return {
      description: "资源化产品产量",
      chartOptions: {

        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,//延Y轴旋转45度
            beta: 0,
            // depth: 45,//设置3D图的深度
          },
          marginRight: 0,
          marginTop: 0,
          backgroundColor: 'rgba(0,0,0,0)',//将背景设置未透明
        },
        //去掉菜单
        exporting: {
          enabled: false,
          buttons: {
            exportButton: {
              enabled: false
            }
          }
        },
        title: {
          text: '',
          style: {
            "color": "#fff",
            "fontSize": "15px",
          },
          align: 'left',
        },
        tooltip: {
          enabled: false
          //pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: false,
            cursor: 'pointer',
            depth: 25,
            dataLabels: {
              enabled: true,
              distance: -10,
              // format: '{point.y}</b>',
              formatter: function () {
                return `${this.y}万吨  ${this.percentage}%`
              },
              color: '#fff',
            },
            showInLegend: true,
            center: ['50%', '45%'],
            colors: ['#F7A35C', '#7CB5EC', '#3EB4B0', '#90ED7D'],
          }
        },
        legend: {
          enabled: true,
          itemStyle: {
            fontSize: '14px',
            color: '#fff',

          },
          floating: true
        },

        series: [{
          type: 'pie',
          name: '',
          size: '60%',
          innerSize: '0%',
          data: []
        }],
      },
    }
  },
  methods: {
    loadData() {
      if (document.querySelector('.use-map-1')) {
        this.chartOptions.legend.itemStyle.fontSize = '11px'
      }

      this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 183
      }).then(res => {
        if (res && res.length > 0) {
          let d = []
          res.forEach(item => {
            d.push({
              y: +item.content,
              unit: item.contentUnit,
              name: item.remark
            })
          })
          this.chartOptions.series[0].data = d
        }
      })


    }
  }
}
</script>

<style lang="less" scoped>

/deep/ .highcharts-credits {
  display: none;


}

/deep/ .highcharts-legend.highcharts-no-tooltip {
  transform: translate(0.2rem, 20vh);

  .highcharts-legend-box {
    width: 2.99rem;

  }

  .highcharts-legend-item {
    &:first-child {
      transform: translate(0, 0);
    }

    &:nth-child(2) {
      transform: translate(1.44rem, 0);
    }

    &:nth-child(3) {
      transform: translate(0, 2.5vh);
    }

    &:nth-child(4) {
      transform: translate(1.44rem, 2.5vh);
    }
  }
}
</style>
