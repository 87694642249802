<template>
  <div>
    <div class="surroundContainer">
      <div class="header">
        <div class="icon"></div>
        <div class="title">产能分析</div>
      </div>
      <div class="chart-box">
        <div class="chart" ref="chart"></div>
        <div class="unit">单位： {{ unit }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      datas: [0, 0, 1.5, 5.2, 7.5],
      unit: "万吨",
    };
  },
  methods: {
    initChart() {
      let chart = echarts.init(this.$refs.chart);
      let options = {
        roam: false,
        tooltip: {
          show: false,
        },
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 18,
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0, //横轴信息全部显示
              rotate: 0, //0度角倾斜显示
              show: true,
              fontSize: 12,
              color: "#DAE1EB",
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#586F91",
              },
            },
            axisTick: {
              show: false,
            },
            offset: 8,
            data: [2017, 2018, 2019, 2020, 2021],
          },
          // {
          //   position: "bottom",
          //   offset: 5,
          //   axisLine: {
          //     lineStyle: {
          //       color: "#586F91",
          //     },
          //   },
          // },
        ],
        yAxis: [
          {
            show: true,
            splitLine: {
              show: false,
            },
            axisLabel: {
              color: "#DAE1EB",
              fontSize: 12,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: ["#586F91"],
                type: "solid",
              },
            },
            axisTick:{
              show: false,
            },
            // offset: 6,
          },
        ],

        series: [
          {
            name: "a",
            tooltip: {
              show: false,
            },
            data: this.datas,
            hoverAnimation: false,
            type: "bar",
            barWidth: 9,
            // barGap: 200,
            // barCategoryGap: 200,

            label: {
              show: true, // 柱子顶部的数值
              position: "top",
              top: 0,
              fontSize: 12,
              color: "#C3D8F3",
              offset: [4, 0],
              formatter: function(params) {
                let num = params.value;
                if (num * 1 == 0) return "";
                return num;
              },
            },
            itemStyle: {
              normal: {
                color: "#1382D8",
              },
            },

            // barGap: 0
          },
          {
            type: "bar",
            barWidth: 9,
            itemStyle: {
              normal: {
                color: "#50B0DA",
              },
            },
            hoverAnimation: false,
            barGap: 0,
            data: this.datas.map((item) => item),
          },
          {
            name: "b",
            tooltip: {
              show: false,
            },
            type: "pictorialBar",
            itemStyle: {
              borderWidth: 0,
              borderColor: "#50B0DA",
              color: "#359CEC",
            },
            symbol: "diamond",
            symbolSize: ["18", "12"],
            symbolOffset: ["0", "-6"], // 左右 ，上下
            symbolRotate: 0,
            symbolPosition: "end",
            data: this.datas,
            z: 3,
          },
          // {
          //   name: "d",
          //   tooltip: {
          //     show: false,
          //   },
          //   type: "pictorialBar",
          //   itemStyle: {
          //     //底部
          //     normal: {
          //       color: function(params) {
          //         let colorList = ["#1382D8"];
          //         return colorList[0];
          //       },
          //       borderColor: "#000",
          //       borderWidth: 0,
          //       label: {
          //         show: false, //开启显示
          //         position: "top", //在上方显示
          //         textStyle: {
          //           //数值样式
          //           color: "#FFFFFF",
          //           fontSize: 14,
          //           fontFamily: "微软雅黑",
          //         },
          //         offset: [0, -6],
          //       },
          //     },
          //   },
          //   symbol: "diamond",
          //   symbolSize: ["18", "6"],
          //   symbolOffset: [0, "3.8"],
          //   symbolPosition: "start",
          //   data: this.datas,
          //   z: 0,
          // },
        ],

        // 可左右滑动
        // dataZoom: [{
        //     show: false,
        //     realtime: true,
        //     start: 30,
        //     end: 50
        // }, {
        //     type: 'inside',
        //     realtime: false,
        //     start: 30,
        //     end: 50,
        //     zoomOnMouseWheel: false, // 禁用滚轮
        // }],
      };
      let option = {
        roam: false,
        tooltip: {
          show: false,
        },
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 16,
        },

        xAxis: {
          axisLabel: {
            interval: 0, //横轴信息全部显示
            rotate: 0, //0度角倾斜显示
            show: true,
            fontSize: 12,
            color: "#DAE1EB",
          },
          axisLine: {
            lineStyle: {
              color: "#586F91",
            },
          },
          axisTick: {
            show: false,
          },
          data: [2017, 2018, 2019, 2020, 2021],
          offset: 8,
        },
        yAxis: {
          show: true,
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#DAE1EB",
            fontSize: 12,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ["#586F91"],
              type: "solid",
            },
          },
          z: 4,
        },
        // axisPointer: {
        //   show: false,
        //   link: { xAxisIndex: "all" },
        //   type: "shadow",
        //   label: {
        //     backgroundColor: "#777",
        //   },
        // },
        series: [
          {
            name: "资源数量统计",
            type: "bar",
            // showSymbol: false,
            hoverAnimation: false,
            data: this.datas,
            barWidth: 10, //柱图宽度
            // barCategoryGap:'60%',
            itemStyle: {
              //左面
              normal: {
                color: function(params) {
                  let colorList = ["#7EEEFB"];
                  return colorList[0];
                },
                barBorderRadius: [4, 0, 0, 100],
              },
            },
          },
          {
            name: "资源数量统计",
            tooltip: {
              show: false,
            },
            type: "bar",
            barWidth: 10,
            // barCategoryGap:'60%',
            itemStyle: {
              //右面
              normal: {
                color: function(params) {
                  let colorList = ["#48D9F5"];
                  return colorList[0];
                },
                borderWidth: 0.1,
                barBorderRadius: [0, 5, 100, 0],
              },
            },
            data: this.datas,
            barGap: 0,
          },
          {
            name: "b",
            tooltip: {
              show: false,
            },
            type: "pictorialBar",
            itemStyle: {
              //顶部
              normal: {
                color: function(params) {
                  let colorList = ["#48D9F5"];
                  return colorList[0];
                },
                borderColor: "#000",
                borderWidth: 0.1,
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#FFFFFF",
                    fontSize: 14,
                    fontFamily: "微软雅黑",
                  },
                  offset: [0, -2],
                },
              },
            },
            symbol: "diamond",
            symbolSize: ["20.5", "13"],
            symbolOffset: [0, "-38%"],
            symbolPosition: "end",
            data: this.datas,
            z: 3,
          },
          {
            name: "d",
            tooltip: {
              show: false,
            },
            type: "pictorialBar",
            itemStyle: {
              //底部
              normal: {
                color: function(params) {
                  let colorList = ["#48D9F5"];
                  return colorList[0];
                },
                borderColor: "#000",
                borderWidth: 0,
                label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#FFFFFF",
                    fontSize: 14,
                    fontFamily: "微软雅黑",
                  },
                  offset: [0, -2],
                },
              },
            },
            symbol: "diamond",
            symbolSize: ["18", "12"],
            symbolOffset: [0, "2.5"],
            symbolPosition: "start",
            data: this.datas,
            z: 0,
          },
        ],
      };
      chart.setOption(options);
    },
  },
  mounted() {
    this.initChart();
  },
};
</script>
<style lang="less" scoped>
@import url("../common.less");

.chart {
  width: 100%;
  padding-top: 19px;
  height: calc(100% - 10px);
}
.unit {
  position: absolute;
  width: 95%;
  left: 16px;
  top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  color: #b2bed1;
  font-size: 12px;
  .cele {
    border: 1px solid #343e4f;
    border-radius: 5px;
  }
  .btn {
    width: 35px;
    height: 25px;
    //   border: 1px solid #343e4f;
    text-align: center;
    //   margin-right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .btnactive {
    width: 35px;
    height: 25px;
    border: 1px solid #00fff7;
    text-align: center;
    //   margin-right: 5px;
    color: #00fff7;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
  }
  .right-top {
    width: 8px;
    height: 8px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .right-bottom {
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .left-bottom {
    width: 16px;
    height: 16px;
    border-bottom: 2px solid #00fff7;
    border-left: 2px solid #00fff7;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .left-top {
    width: 8px;
    height: 8px;
    border-top: 2px solid #00fff7;
    border-right: 2px solid #00fff7;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
</style>
