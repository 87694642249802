<template>


  <surroundBox :name="description"  desc="对社会的贡献(扶持就业、<br/>带动就业、学生实践等)">
    <template slot="content">
      <div class="itembox">
        <div v-for="(item,index) in dataSource" :key="index" class="item">
          <div class="icon">
            <img :src="require('@/assets/imgs/icon/' + item.img + '.png')" alt=""/>
          </div>
          <div class="desc">
            <div class="t">{{ item.text }}</div>
            <div class="b">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </template>
  </surroundBox>
</template>

<script>

import surroundBox from "../surroundBox/surroundBox";

export default {
  components: {
    surroundBox,
  },
  name: "socialBenefits",
  data() {
    return {
      description: "社会效益",
      dataSource: [
        {
          text: '20人以上',
          img: '创业申请查询',
          content: '预计年扶持创业'
        },
        {
          text: '100人以上',
          img: '创业申请查询-1',
          content: '预计带动就业'
        },
        {
          text: '20人以上',
          img: '创业申请查询-2',
          content: '学生实践'
        },
        {
          text: '3000余元',
          img: '创业申请查询-3',
          content: '预计户均增收'
        },
        {
          text: '100次以上',
          img: '创业申请查询-4',
          content: '知识座谈'
        },
        {
          text: '30余种',
          img: '创业申请查询-5',
          content: '新品研发'
        },
      ]
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.dataSource = []
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 182
      }).then(res => {
        if (res && res.length > 0) {
          res.forEach((item, index) => {
           this.dataSource.push({
             text: item.content,
             img: `创业申请查询${item.fileUrl ? '-' + item.fileUrl : ''}`,
             content: item.remark
           })
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>

.itembox {
  width: 100%;
  height: 100%;
  padding: 0 0.14rem;
  padding-top: 2.778vh;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;

  .item {
    display: flex;
  }

  .icon {
    width: 0.44rem;
    height: 0.44rem;
    //border: 1px solid #707070;
    border-radius: 50%;
    margin-right: 0.05rem;
  }

  .desc {
    .t {
      color: #25FFFF;
      font-size: 0.12rem;
    }

    .b {
      color: #fff;
      font-size: 0.12rem;
    }
  }
}
</style>
