<template>
  <div ref="echartMap" style="width:100%;height: 100%;"></div>

</template>

<script>
import * as echarts from "echarts";
import 'echarts-gl'
import {mapState} from "vuex";

const siteType2Img = '/imgs/map/sitetype3.png';

export default {
  name: "echart-map",
  props: {
    areaId: {
      type: String,
      default: "100000", // 默认中国

    },
    center: {
      type: Array,
      default: () => {
        return [103.80643, 31.133287]
      }
    }
  },
  data() {
    return {
      description: "",
      myEchart: null,
      westNorthArea: new Set([
        "新疆维吾尔自治区",
        "青海省",
        "甘肃省",
        "宁夏回族自治区",
        "陕西省",
      ]),
      westSouthArea: new Set([
        "西藏自治区",
        "四川省",
        "云南省",
        "重庆市",
        "云南省",
        "贵州省",
      ]),
      northArea: new Set([
        "内蒙古自治区",
        "山西省",
        "河北省",
        "北京市",
        "天津市",
      ]),
      centerArea: new Set(["河南省", "湖北省", "湖南省"]),
      southArea: new Set([
        "广西壮族自治区",
        "广东省",
        "海南省",
        "香港特别行政区",
        "澳门特别行政区",
      ]),
      eastNorthArea: new Set(["黑龙江省", "吉林省", "辽宁省"]),
      eastArea: new Set([
        "山东省",
        "江苏省",
        "安徽省",
        "浙江省",
        "江西省",
        "福建省",
        "台湾省",
        "上海市",
      ]),
      farmDotSeries: null
    }
  },
  computed: {
    ...mapState({
      farmReady: (state) => state.map.farmReady,
      farmList: (state) => state.map.farmList,
    }),
  },
  mounted() {
    this.initMap()
    this.addFarmDot();
  },
  methods: {
    // 初始化地图
    initMap() {
      this.myEchart = echarts.init(this.$refs.echartMap);
      let cityJson = require('./json/' + this.$props.areaId + '.json')
      this.myEchart.on('click', params => {
        if (params.seriesId === 'farmDot') {
          //   基地点击
          this.$root.$emit('clickRoom', params.data.data)
        }
      })
      this.setOption('中国', cityJson)
    },

    setOption(cityName, cityJson) {
      echarts.registerMap(cityName, cityJson);
      let option = {
//地理坐标系组件。地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
        geo: {
          map: cityName,
          // roam: true,
          aspectScale: 0.85,
          zoom: 0.7,
          center: this.$props.center,
          roam: true, // 是否可以缩放
          layoutCenter: ['50%', '55%'],
          layoutSize: '100%',
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: '#0c274b',
              borderColor: '#1cccff',
              borderWidth: 1

            },
            emphasis: {
              areaColor: '#0c274b',
              label: {
                show: false,
              }

            }
          },

          regions: [...this.initRegios()],
        },
        //系列列表。每个系列通过 type(map, scatter, bar, line, gauge, tree.....) 决定自己的图表类型
        series: []
      }
      if (this.farmDotSeries) {
        option.series.push(this.farmDotSeries)
      }
      this.myEchart.clear()
      option && this.myEchart.setOption(option)
    },

    // 根据不同地区生产不同的颜色
    initRegios() {
      let item = [this.westNorthArea, this.westSouthArea, this.northArea, this.centerArea, this.southArea, this.eastNorthArea, this.eastArea]
      let v = []
      item.forEach(res => {
        res.forEach(item => {
          let color = this.areaColor(item)
          v.push({
            name: item,
            itemStyle: {
              normal: {
                areaColor: color
              },
              emphasis: {
                areaColor: color
              }
            }
          })
        })
      })


      return v
    },
    areaColor(name) {
      if (this.westNorthArea.has(name)) return "#5490D5";
      if (this.westSouthArea.has(name)) return "#2E77AF";
      if (this.northArea.has(name)) return "#49A3DF";
      if (this.centerArea.has(name)) return "#6FBCFF";
      if (this.southArea.has(name)) return "#3B64C2";
      if (this.eastNorthArea.has(name)) return "#1A68AA";
      if (this.eastArea.has(name)) return "#3685E0";
    },
    //验证数组是否存在某元素
    in_array(value, array) {
      for (var i in array) {
        if (array[i] === value) {
          return true
        }
      }
      return false
    },
    // 加载所有基地点
    addFarmDot() {
      if (!this.farmReady) {
        this.$store.dispatch("getAllFarms").then((list) => {
          this.addFarmDotDo(list);
        });
      } else {
        this.addFarmDotDo(this.farmList);
      }
    },
    // 添加基地点
    addFarmDotDo(farmList) {

      let list = farmList.filter(item => {
        return +item.siteType === 3
      })

      this.farmDotSeries = {
        id: 'farmDot',
        map: '中国',
        type: 'scatter',
        coordinateSystem: 'geo',
        // zlevel: 4,

        symbolSize: 10,
        // rippleEffect: { //坐标点动画
        //   period: 3,
        //   scale: 5,
        //   brushType: 'fill'
        // },
        label: {
          normal: {
            show: false,
          },
        },
        emphasis: {
          label: {
            show: true,
            color: '#fff',
            fontSize: 16,
            offset: [0, -20],
            formatter: (series) => {
              return series.name
            }
          }
        },
        // symbol: 'image://' + siteType2Img,
        data: list.map(res => {
          let [lng, lat] = res.mapCenterLocation.split(',')
          return {
            symbolSize: [24,31],
            name: res.sourceName,
            value: [+lng, +lat],
            id: res.sourceId,
            siteType: res.siteType,
            symbol: 'image://' + siteType2Img,
            data: {...res}
          }
        }),


        // itemStyle: {
        //   normal: {
        //     show: true,
        //     color: '#ff8003',
        //     shadowBlur: 20,
        //     shadowColor: '#fff'
        //   },
        //   emphasis: {
        //     areaColor: '#f00'
        //   }
        // }


      }
      this.myEchart && this.myEchart.setOption({series: [this.farmDotSeries]})
    }
  },

}
</script>

<style lang="less" scoped>

</style>
