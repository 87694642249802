<template>
  <div class="use-map use-map-1">
    <div class="map-container-box">
      <div class="map-container-1">
<!--        <div class="bg"></div>-->
        <echartMap></echartMap>
      </div>
<!--      <div class="map-container">-->
<!--      -->
<!--                <use-map-->
<!--                    ref="map"-->
<!--                    :center="[103.80643, 36.123287]"-->
<!--                    :currentFarm="currentFarm"-->
<!--                    :farmFilter="farmFilter"-->
<!--                    :farms="nodalPoint"-->
<!--                    :useType="1"-->
<!--                    :zoom="5"-->
<!--                    boundaryName="中国"-->
<!--                    mapType="normal"-->
<!--                    @farm-change="farmClick"-->
<!--                    @site-click="siteClick"-->
<!--                    @zoom-change="zoomChange"-->
<!--                ></use-map>-->
<!--        <div class="icon-container">-->
<!--          <span-->
<!--              style="-->
<!--          width: 100%;-->
<!--          height: 100%;-->
<!--          position: absolute;-->
<!--          left: 0;-->
<!--          top: 0;-->
<!--          background: none;-->
<!--          z-index: 999;-->
<!--          display: flex;-->
<!--          align-items: center;-->
<!--          pointer-events: none;-->
<!--        "-->
<!--          >-->
<!--            <img src="/imgs/map/lianhe.png" style="width: 100%"/>-->
<!--          </span>-->
<!--          <span-->
<!--              style="-->
<!--          width: 100%;-->
<!--          height: 100%;-->
<!--          position: absolute;-->
<!--          left: 0;-->
<!--          top: 0;-->
<!--          background: none;-->
<!--          z-index: 999;-->
<!--          display: flex;-->
<!--          align-items: center;-->
<!--          justify-content: center;-->
<!--          pointer-events: none;-->
<!--        "-->
<!--          >-->
<!--            <img src="/imgs/map/circle.png" style="width: 95%"/>-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <template>
      <div class="leftEchart">

        <div v-if="!currentFarm">
          <accept-waste>

          </accept-waste>
          <accept-straw>

          </accept-straw>
          <process-waste></process-waste>
          <process-straw></process-straw>
          <carbon-neutral class="carbon-neutral"></carbon-neutral>
          <information-level></information-level>
        </div>
      </div>
      <!-- <div class="farm-legend">
        <farm-legend :currentFarm="currentFarm"></farm-legend>
      </div>
      <div class="accept-num">
        <accept-num :currentFarm="currentFarm"></accept-num>
      </div>
      <div class="muscle-account">
        <muscle-account :currentFarm="currentFarm"></muscle-account>
      </div> -->
      <!-- <div class="sale-account">
        <sale-pane :currentFarm="currentFarm"></sale-pane>
      </div> -->

      <div class="rightEchart">
        <wasterProcessingCapacity></wasterProcessingCapacity>
        <recyclingProductForm></recyclingProductForm>
        <recyclingUseServiceEntities></recyclingUseServiceEntities>
        <recyclingProductsProduction class="recyclingProductsProduction"></recyclingProductsProduction>
        <economicBenefits></economicBenefits>
        <socialBenefits></socialBenefits>
        <!--        <wasteProcessingAnalysis></wasteProcessingAnalysis>-->
      </div>

      <div class="bottomCenter">
        <bottomCenter :remarkData="remarkData"></bottomCenter>
      </div>
      <div class="topCenter1">
        <topCenter></topCenter>
      </div>
      <div class="colorShili">
        <div v-for="(item, index) in areaColors" :key="index">
          <span> {{ item.title }}</span>
          <div :style="{ backgroundColor: item.color }" class="color"></div>
        </div>
        <div>
          <span>加工厂</span>
          <div class="icon"></div>
        </div>
      </div>
    </template>
  </div>
</template>
<!-- <div class="farm-video" v-if="videoShow">
      <farm-video :site="videoSite" @video-close="videoClose"></farm-video>
    </div>
    <div class="monitor-panel" v-if="monitorShow">
      <monitor
        :site="currentSite"
        @show-chart="showChart"
        @monitor-close="monitorClose"
      ></monitor>
    </div>
    <chart-pane
      v-if="chartPaneShow"
      :data="chartData"
      @close-pane="closePane"
    ></chart-pane> -->

<script>
// import useMap from "./map";
import useMap from "@/components/map/WlwMap";
import farmLegend from "./component/farmLegend"; //图例
import farmVideo from "./component/farmVideo"; //视频监控
import monitor from "./component/monitor"; //监测
import chartPane from "./component/chartPane";
import salePane from "./component/sale";

import wasteProcessingAnalysis from "./component/wasteProcessingAnalysis"; //粪污加工累计量分析
import capacityAnalysis from "./component/capacityAnalysis"; //产能分析
import salesTypeRanking from "./component/salesTypeRanking"; // 销售总类排行
import carbonNeutral from "./component/carbonNeutral"; // 碳中和
import bottomCenter from "./component/bottomCenter"; // 中间下面部分
import acceptStraw from "./component/accept/straw" // 秸秆收纳
import acceptWaste from "./component/accept/waste" // 粪污收纳
import processWaste from "./component/process/waste" // 粪污加工
import processStraw from "./component/process/straw" // 秸秆加工
import informationLevel from "./component/informationLevel/informationLevel"; // 信息化水平
import wasterProcessingCapacity from "./component/wasteProcessingCapacity/wasteProcessingCapacity" // 废弃物加工产能
import recyclingProductForm from "./component/recyclingProductForm/recyclingProductForm"; // 资源化产品形态
import economicBenefits from "./component/economicBenefits/economicBenefits"; // 经济效益
import socialBenefits from "./component/socialBenefits/socialBenefits" // 社会效益
import recyclingUseServiceEntities from "./component/recyclingUseServiceEntities/recyclingUseServiceEntities" // 资源化利用服务实体
import recyclingProductsProduction from "./component/recyclingProductsProduction/recyclingProductsProduction" // 资源化产品产量
import topCenter from "./component/topCenter/topCenter";
import echartMap from "./map/echart-map";

export default {
  name: "",
  components: {
    useMap,
    farmLegend,
    farmVideo,
    monitor,
    chartPane,
    salePane,
    wasteProcessingAnalysis,
    capacityAnalysis,
    salesTypeRanking,
    carbonNeutral,
    bottomCenter,
    acceptStraw,
    acceptWaste,
    processWaste,
    processStraw,
    informationLevel,
    wasterProcessingCapacity,
    recyclingProductForm,
    socialBenefits,
    economicBenefits,
    recyclingUseServiceEntities,
    recyclingProductsProduction,
    topCenter,
    echartMap
  },
  data() {
    return {
      nodalPoint: [],
      nodalPointId: "",
      currentFarm: null,
      currentSite: null,
      videoShow: false,
      monitorShow: false,
      videoSite: null,
      chartPaneShow: false,
      chartData: {},
      farmFilter: new Set("3", 3),
      remarkData: {
        imgs: [],
      },
      areaColors: [
        {
          title: "西北地区",
          color: "#5490D5",
        },
        {
          title: "西南地区",
          color: "#2E77AF",
        },
        {
          title: "华北地区",
          color: "#49A3DF",
        },
        {
          title: "华中地区",
          color: "#6FBCFF",
        },
        {
          title: "华南地区",
          color: "#3B64C2",
        },
        {
          title: "东北地区",
          color: "1A68AA",
        },
        {
          title: "华东地区",
          color: "#3685E0",
        },
      ],
    };
  },
  // watch: {
  //   nodalPointId(n) {
  //     if (!n) return;
  //     this.currentFarm = this.nodalPoint.filter(
  //       (item) => item.sourceId == n
  //     )[0];
  //   },
  // },

  methods: {
    getDarmData(id = "1299") {
      this.remarkData = {
        imgs: [],
      }
      this.$post(this.$api.BASE_MANAGE.INFO, {
        id: id,
      }).then((res) => {
        const regexImgs = /((http|https).*?\.(jpg|png|jpeg|webp))/gim;
        const regexText = /<img.*?\/>/gim;
        let imgs = res.remark.match(regexImgs) || [];
        // if (!!res.remark) {
        let text = res.remark.replace(regexText, "");
        text = text.replace("color: rgb(0, 0, 0);", "");
        text = text.replace("#000000", "");
        this.remarkData = {
          imgs: imgs,
          text: text,
          companyName: res.name || res.companyName,
        };
        // }

        // let remark = res.remark;
      });
    },
    getFarmSelect(value) {
      if (!value) return;
      let farm = this.nodalPoint.find((item) => item.sourceId == value);
      if (farm) {
        this.$refs.map.$refs.baseMap.farmClick(farm);
      }
    },
    getNodalPoint() {
      this.$post(this.$api.MAP.MAP_CFG_INFO, {
        farmType: "3",
      }).then((res) => {
        this.nodalPoint = res.list;
      });
    },
    farmClick(farm) {
      console.log(farm);
      this.currentFarm = farm;
      if (!farm) this.nodalPointId = "";
      // this.nodalPointId = farm ? farm.sourceId : "";
    },
    siteClick(site) {
      // console.log(site);
      if (site.siteType == 7) {
        this.videoSite = site;
        this.videoShow = true;
        return;
      }
      this.currentSite = site;
      if (site.siteType == 1 || site.siteType == 6) {
        this.monitorShow = true;
      }
    },
    zoomChange(zoom) {
      if (zoom < 13) {
        this.currentFarm = null;
        this.nodalPointId = "";
        this.videoShow = false;
        this.monitorShow = false;
      }
    },
    videoClose() {
      this.videoShow = false;
      this.videoSite = null;
    },
    monitorClose() {
      this.monitorShow = false;
      this.currentSite = null;
    },
    showChart(data) {
      this.chartPaneShow = true;
      this.chartData = {...data};
    },
    closePane() {
      this.chartPaneShow = false;
      this.chartData = {};
    },
  },
  mounted() {
    this.getNodalPoint();
    this.getDarmData();
    this.$root.$on("clickRoom", (item) => {
      this.getDarmData(item.sourceId);
    });
    this.$nextTick(()=> {
      let t = document.querySelector(".leftEchart >div>div:last-child").offsetTop;
      document.querySelector(".bottomCenter").style.top = t + "px";
    })
    window.addEventListener("resize", () => {
      let t = document.querySelector(".leftEchart >div>div:last-child").offsetTop;
      document.querySelector(".bottomCenter").style.top = t + "px";
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
    });
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
