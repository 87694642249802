<template>
  <surroundBox :name="description" desc="自动化、智能化、信息化的程度">
    <template slot="content">
      <div class="informationLevel-echart" style="width:100%;height:100%"></div>
    </template>
  </surroundBox>
</template>

<script>
import * as echarts from 'echarts'
import surroundBox from "../surroundBox/surroundBox";

export default {
  name: "informationLevel",
  components: {
    surroundBox,
  },
  data() {
    return {
      description: "信息化水平",
      chart: null,
      values: [],
      indicator: [],
    }
  },
  mounted() {
    this.loadData().then(() => {
      this.initCharts();
    });
    window.addEventListener('resize', () => {
      this.chart && this.chart.resize()
    })
  },
  methods: {
    initCharts() {
      if (this.chart == null) {
        this.chart = echarts.init(document.querySelector(".informationLevel-echart"))
      }
      let option = {

        radar: [
          {
            indicator: this.indicator,
            center: ['50%', '55%'],
            radius: 80,
            startAngle: 90,
            splitNumber: 2,
            shape: 'circle',
            axisName: {
              formatter: '{value}',
              color: '#fff'
            },
            splitArea: {
              areaStyle: {
                color: 'rgba(72, 63, 93,.28)'
              }
            },
            axisLine: {
              lineStyle: {
                color: '4F4C4C'
              }
            },

            splitLine: {
              lineStyle: {
                color: '#4F4C4C'
              }
            }
          }
        ],

        series: [
          {
            type: 'radar',
            lineStyle: {
              width: 0
            },
            emphasis: {
              lineStyle: {
                width: 0
              }
            },
            symbol: 'none',
            data: [
              {
                value: this.values,
                name: 'Data B',
                areaStyle: {
                  color: {
                    type: 'radial',
                    x: 0.7,
                    y: 0.6,
                    r: 0.7,
                    colorStops: [
                      {
                        offset: 1,
                        color: 'rgba(34,199,184, 1)' // 0% 处的颜色
                      },
                      {
                        offset: 0,
                        color: 'rgba(58,132,255, 0)' // 100% 处的颜色
                      }
                    ]
                  },
                }
              }
            ]
          }
        ]
      }
      this.chart.setOption(option)
    },
    loadData() {
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 170
      }).then(res => {
        if (res && res.length > 0) {
          res.forEach((item, index) => {
            this.values.push(+item.content)
            this.indicator.push({
              text: item.dimensionName,
              axisLabel: {
                show: index === 1
              }
            })
          })
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>

