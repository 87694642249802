<template>
  <div>
    <div class="surroundContainer transition_none">
      <div class="header">
        <div class="icon"></div>
        <div class="title">秸秆收纳总量分析</div>
      </div>
      <div class="chart-box">
        <div class="sales">
          <div class="item" v-for="(item, index) in datas" :key="index">
            <div class="le" :class="[{ end: index == datas.length - 1 }]">
              <p>TOP</p>
              <p>{{ (Array(2).join(0) + (index + 1)).slice(-2) }}</p>
            </div>
            <transition name="option">
              <div class="option" :style="item.style">
                <div class="cc">
                  {{ item.name }} &nbsp;&nbsp;&nbsp;&nbsp; {{ item.value }}万吨
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datas: [
        {
          name: "通用生物有机肥",
          value: 6.39,
          style: {
            width: "80%",
          },
        },
        {
          name: "精致生物有机肥",
          value: 4.26,
          style: {
            width: "50%",
          },
        },
        {
          name: "水产专用肥",
          value: 2.13,
          style: {
            width: "40%",
          },
        },
        {
          name: "有机家庭系列",
          value: 1.42,
          style: {
            width: "20%",
          },
        },
      ],
    };
  },
  mounted() {
    window.onload = function() {
      document
        .querySelector(".transition_none")
        .classList.remove("transition_none");
    };
  },
};
</script>
<style lang="less" scoped>
@import url("../common.less");
.chart-box {
  width: calc(100% - 0.1rem);
}
.sales {
  .item {
    margin-top: 1vh;
    display: flex;
    padding-left: 0.25rem;
    .le p {
      color: #00b2ff;
      font-size: 0.14rem;
      font-weight: bold;
      text-align: center;
    }

    .le.end p {
      color: #b2bed1;
    }
  }
  .option {
    margin-left: 0.16rem;
    max-height: 40px;
    height: 3.9vh;
    width: 0%;
    color: #d5e5ff;
    transition: all 1s;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(82, 104, 255, 0.07) 0%,
      rgba(117, 81, 216, 0.33) 28%,
      rgba(79, 255, 173, 0.61) 58%,
      rgba(78, 154, 219, 0.83) 82%,
      #4da6ff 100%
    );
    border-radius: 0px 21px 21px 0px;

    .cc {
      position: absolute;
      white-space: nowrap;
      max-height: 40px;
      height: 3.9vh;
      line-height: 3.9vh;
      left: 0.08rem;
    }
  }
}

.option-enter {
  width: 0;
}
.option-enter-active {
  transition: all 1s;
}

@keyframes optionAnimation {
  50% {
    width: 50%;
  }
}

.transition_none * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>
