<template>
  <div class="video-pane">
    <div class="sel-box">
      <Select v-model="siteGuid" style="width: 200px">
        <Option
          v-for="item in getVideoSites"
          :value="item.sensorGuids"
          :key="item.guid"
          >{{ item.name }}</Option
        >
      </Select>
      <span class="close" @click="close">×</span>
    </div>
    <div class="video-box">
      <player :videoInfo="videoInfo"></player>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import player from "@/components/player"
export default {
  name: "",
  components: {
    player
  },
  props: {
    site: Object,
  },
  computed: {
    ...mapState({
      sites: (state) => state.map.sites,
    }),
    getVideoSites() {
      return this.sites.filter((item) => item.siteType == 7);
    },
  },
  watch: {
    siteGuid(n) {
      this.getVideoInfo(n)
    }
  },
  data() {
    return {
      siteGuid: "",
      videoInfo: {}
    };
  },
  methods: {
    getVideoInfo(id) {
      this.$post(this.$api.BUSSDEVICEVIDEO.VIEW, {
        id
      }).then(res => {
        this.videoInfo = res
      })
    },
    close() {
      this.$emit('video-close')
    }
  },
  mounted() {
    if (this.site) {
      this.siteGuid = this.site.sensorGuids;
    }
  },
};
</script>

<style lang="less" scoped>
.video-pane {
  width: 100%;
  height: 100%;
  padding: 16px;
  background: rgba(23, 34, 60, 0.6);
  .sel-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      cursor: pointer;
      color: #e0e0e0;
      font-size: 20px;
      &:hover {
        color: #fff;
      }
    }
    /deep/ .ivu-select-selection {
      background-color: rgba(23, 34, 60, 0.6);
      border: none;
      box-shadow: none;
    }
    /deep/ .ivu-select-dropdown {
      background-color: rgba(23, 34, 60, 1);
    }
    /deep/ .ivu-select-selected-value {
      color: #fff;
    }
    /deep/ .ivu-select-placeholder {
      color: #fff;
    }
    /deep/ .ivu-select-arrow {
      color: #fff;
    }
    /deep/ .ivu-select-item {
      color: #fff;
    }
    /deep/ .ivu-select-item:hover {
      background: rgba(61, 90, 94, 0.78);
    }
    /deep/ .ivu-select-item-focus {
      background: rgba(61, 90, 94, 0.78);
    }
  }
  .video-box {
    height: calc(100% - 30px);
    width: 100%;
    padding-top: 16px;
  }
}
</style>